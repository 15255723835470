import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ObjectFieldRow = ({ label, value }) => (value ? (
  <tr className={styles.objectFieldRow}>
    <td className={styles.label}>
      <h3>{label}</h3>
    </td>
    <td className={styles.value}>{value}</td>
  </tr>
) : null);

ObjectFieldRow.defaultProps = {
  value: null,
};

ObjectFieldRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ObjectFieldRow;
