import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Table = ({ className, ...props }) => (
  <table className={`${styles.table} ${className}`} {...props} />
);

Table.defaultProps = {
  className: '',
};

Table.propTypes = {
  className: PropTypes.string,
};

export default Table;
