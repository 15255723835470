import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PageTitle from '../../elements/PageTitle';
import Columns from '../../elements/Columns';
import Column from '../../elements/Column';
import Table from '../../elements/Table';
import Container from '../../components/core/Container';
import BackLink from '../../components/core/BackLink';
import ObjectFieldRow from '../../components/collection/ObjectFieldRow';
import ObjectFieldSection from '../../components/collection/ObjectFieldSection';
import styles from './styles.module.css';
import Layout from '../../components/core/Layout';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const Object = ({ data }) => {
  const object = data.objectsJson;

  const hasImages = !!object.fields;
  const title = capitalize(object.title || object.type);

  return (
    <Layout>
      <Helmet title={`${title} - ${data.site.siteMetadata.title}`} />
      {hasImages && (
        <Img className={styles.mainImage} sizes={object.fields.main_image.childImageSharp.sizes} />
      )}
      <Container>
        <PageTitle>{title}</PageTitle>
        <BackLink text="Back" />
        <Columns className={styles.columns} style={{ marginTop: '1em' }}>
          <Column className={styles.column}>
            {object.gateway && (
              <div className={styles.gateway}>
                This object is a Gateway object. Gateway objects are significant artefacts in the
                collection and are used to represent and introduce bigger subjects and themes.
              </div>
            )}
            <ObjectFieldSection label="Description:" value={object.desc} />
            <Table>
              <tbody>
                <ObjectFieldRow label="Object type:" value={object.type} />
                <ObjectFieldRow label="Museum number:" value={object.mn} />
                <ObjectFieldRow label="School/style:" value={object.style} />
                <ObjectFieldRow label="Culture/period:" value={object.culture} />
                <ObjectFieldRow label="Date:" value={object.date} />
                <ObjectFieldRow label="Production place:" value={object.prod_place} />
                <ObjectFieldRow label="Findspot:" value={object.findspot} />
                <ObjectFieldRow label="Materials:" value={object.mat} />
                <ObjectFieldRow label="Technique:" value={object.technique} />
                <ObjectFieldRow label="Dimensions:" value={object.dimensions} />
                <ObjectFieldRow label="Inscriptions:" value={object.inscription} />
                <ObjectFieldRow label="Location:" value={object.location} />
                <ObjectFieldRow label="Exhibition history:" value={object.exhib_hist} />
                <ObjectFieldRow label="Subjects:" value={object.subjects} />
                <ObjectFieldRow label="Associated names:" value={object.assoc_names} />
                <ObjectFieldRow label="Associated titles:" value={object.assoc_titles} />
                <ObjectFieldRow label="Associated places:" value={object.assoc_places} />
                <ObjectFieldRow label="Acquisition names:" value={object.acqu_name} />
                <ObjectFieldRow label="Acquisition date:" value={object.acqu_date} />
                <ObjectFieldRow label="Department:" value={object.dept} />
              </tbody>
            </Table>
            <ObjectFieldSection label="Curator's comments:" value={object.comments} />
          </Column>
          <Column className={styles.column}>
            <Carousel>
              {hasImages
                && data.objectsJson.fields.all_images.map(image => (
                  <div
                    key={image.name}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <img src={image.publicURL} alt="" />
                  </div>
                ))}
            </Carousel>
          </Column>
        </Columns>
      </Container>
    </Layout>
  );
};

Object.propTypes = {
  data: PropTypes.shape({
    objectsJson: PropTypes.object.isRequired,
  }).isRequired,
};

export default Object;

export const query = graphql`
  query($prn: String!) {
    objectsJson(prn: { eq: $prn }) {
      prn
      title
      type
      mn
      style
      culture
      date
      prod_place
      findspot
      mat
      technique
      dimensions
      inscription
      location
      exhib_hist
      subjects
      assoc_names
      assoc_places
      assoc_titles
      acqu_name
      acqu_date
      desc
      comments
      gateway
      fields {
        main_image {
          ...CardImageSizesFragment
        }
        all_images {
          name
          publicURL
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
