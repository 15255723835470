import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Columns = ({ className, ...props }) => (
  <div className={`${styles.columns} ${className}`} {...props} />
);

Columns.defaultProps = {
  className: '',
};

Columns.propTypes = {
  className: PropTypes.string,
};

export default Columns;
