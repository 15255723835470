import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ObjectFieldSection = ({ label = null, value = null }) => value && (
<div>
  {label && <h3 className={styles.label}>{label}</h3>}
  <p className={styles.value}>{value}</p>
</div>
);

ObjectFieldSection.defaultProps = {
  label: null,
  value: null,
};

ObjectFieldSection.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ObjectFieldSection;
